<template>
  <div class="">
    <div class="h-screen bg-wedding-skyblue" v-if="initBgPage">
      <div class="h-full w-full flex flex-col justify-center items-center">
        <img src="@/assets/svg/icon-logo.svg" alt="">
        <p class="text-lg text-wedding-pink" style="font-family: Anuphan_semibold;">จัดการงานแต่งง่ายๆ แค่ปลายนิ้ว</p>
      </div>
    </div>

    <div class=" w-full" v-else>
      <div class="flex flex-col justify-center px-3 py-2 md:p-5" style="height: 10vh;">
        <p class="text-xl" style="font-family: DM_Sans_bold"> {{ groomName }} & {{ brideName }}</p>
        <p style="font-family: DM_Sans">Wedding</p>
      </div>

      <div
        :style="`height: 75vh;`"
        class="sm:w-96 sm:mx-auto sm:fixed sm:top-1/2 sm:left-1/2 transform sm:-translate-x-1/2 sm:-translate-y-1/2"
      >
        <carousel-slick
          id="carousel-welcome"
          :images="images"
        />
      </div>

      <div class="sm:w-96 sm:mx-auto sm:fixed sm:left-1/2 transform sm:-translate-x-1/2 sm:-bottom-0 flex justify-center px-3" style="height: 15vh;">
        <router-link
            id="btnRegister"
            style="text-decoration: unset; width: 100%;"
            :to="'/form'"
          >
          <button 
            class="h-12 w-full px-5 mt-5 text-white transition-colors duration-150 bg-wedding-blue rounded-full focus:shadow-outline hover:bg-wedding-blue"
          >
              ลงทะเบียน
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CarouselSlick from '../components/CarouselSlick.vue'
import signupFormService from '../services/database/signupForm'
import { delay } from '../utility/functions/delay'

export default {
  components: {
    CarouselSlick
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { eventId, code } = route.query
    const router = useRouter()
    const images = computed(() => store.state.signupForm.welcomePageImages)
    const brideName = computed(() => store.state.signupForm.brideName)
    const groomName = computed(() => store.state.signupForm.groomName)

    let initBgPage = ref(true)

    onBeforeMount(() => {
      init()
    })

    onMounted(() => {
      setTimeout(() => {
        initBgPage.value = false
      }, 1000);
    })

    const init = async () => {
      store.dispatch('appState/init', { eventId, code })
      // checkEventIdHave()
    }

    const checkEventIdHave = async () => {
      const eventIdStore = store.state.appState.eventId
      if(!eventIdStore) {
        await delay(500)
        router.push({ path: '/event-not-found' })
        return
      }
      const doc = await signupFormService()._getByDocId(eventIdStore)
      if(!doc.exists) {
        await delay(500)
        router.push({ path: '/event-not-found' })
      }
    }

    return {
      images,
      brideName,
      groomName,
      initBgPage
    }
  }
}
</script>